<template>
  <div>
    <NuxtLayout>
      <NuxtPage :transition="{
        name: 'page',
        mode: 'out-in'
      }" />
    </NuxtLayout>
    <UNotifications />
    <Modal ref="modal"></Modal>
  </div>
</template>

<script lang="ts" setup>
import chalk from "chalk";

useHead({
  title: "Printags",
  script: [{
    src: "/libraries/webfont.js"
  }]
});

const nuxtApp = useNuxtApp();
const { $sockets } = nuxtApp;
$sockets.set({
  name: "main",
  closeOnBeforeunload: false,
  withCredentials: true
});
$sockets.on("connected", () => {
  //console.log(`[SOCKET] Soft connected to ${ $sockets.get().io.uri } from ${ $route.path + "/" }`);
});
$sockets.on("disconnect", () => {
  console.log(`[SOCKET] Disconnected from ${ $sockets.get().io.uri }`);
  // try to reconnect
  if ($sockets.get().connectionTimeout) {
    clearTimeout($sockets.get().connectionTimeout);
  }
  $sockets.get().connectionTimeout = setTimeout(() => {
    console.log(`[SOCKET] Trying to reconnect to ${ $sockets.get().io.uri }`);
    if (!$sockets.get().initiated) $sockets.get().connect();
    else clearTimeout($sockets.get().connectionTimeout);
  }, 100);
});
const modal = ref(null);
onMounted(() => {
  if (!nuxtApp.$swal) nuxtApp.provide("swal", modal.value);
});

const config = useRuntimeConfig();
const env = config.public.env;
if (env === "prod") {
  console.log(chalk.green("HubSpot tracking is enabled"));
  useHead({
    script: [{
      src: "//js-eu1.hs-scripts.com/145177704.js",
      body: true,
      defer: true,
      id: "hs-script-loader",
      async: true
    }]
  });
} else {
  console.log(chalk.yellow("HubSpot tracking is disabled in development mode"), env);
}
window.hsConversationsSettings = {
  loadImmediately: false
};
</script>

<script lang="ts">

export default {
  name: "Index",
  mounted() {
    const config = useRuntimeConfig();
    const env = config.public.env;
    if (env === "prod" && nuxtApp.$user) {
      fetch("/trakingToken").then((res) => {
        const { email, token } = res;
        window.hsConversationsSettings = {
          identificationEmail: email,
          identificationToken: token
        };
        window.HubSpotConversations.widget.load();
        console.log(chalk.green("HubSpot widget is enabled"));
      });
    }
  }
};

</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.3s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
